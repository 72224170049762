<template>
  <div class="ChuangYouXiangMu">
    <div class="ChuangYouXiangMu1">
      <div class="lunboback">
        <div class="lunboback1" @click="handleLogin">
          <el-carousel height="200px">
            <el-carousel-item v-for="item in 4" :key="item">
              <img src="../imag/img_1.png" class="img-lunboback"/>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="jcpx1">
        <div class="map-child-back-back">
          <el-button
              type="text"
              style="color: #024395 !important"
              v-if="showBack"
              @click="inintMap()"
          >
            <i class="el-icon-back"></i> 返回上级
          </el-button>
          <!-- <el-button v-if="showBack" @click="inintMap()">返回上级</el-button> -->
        </div>
        <div class="jcpx1-1" id="map-wrap"></div>
        <div class="jcpx1-2">
          <div class="jcpx1-2-1">
            <p class="p">各地情况 -</p>
          </div>

          <el-table :data="jgData" height="580px">
            <!--            <el-table-column prop="name" label="排名" width="160" class="jcpx1-2-2-2"></el-table-column>-->
            <el-table-column label="地区" width="160" prop="name">
            </el-table-column>
            <el-table-column
                prop="basic"
                label="已通过基本标准及以上"
                width="180"
                class="jcpx1-2-2-2"
                show-overflow-tooltip
            >
              <template #default="{row}">
                {{row.basic}}%
              </template>
            </el-table-column>
            <el-table-column
                prop="value"
                label="已通过推荐标准"
                width="180"
                class="jcpx1-2-2-2"
                show-overflow-tooltip
            >
              <template #default="{row}">
                {{row.value}}%
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!--      <div class="tongzhigonggao">-->
      <!--        <div class="zixundongtai1">-->
      <!--          <div class="zixundongtai2">-->
      <!--            <p class="zixundongtai3">通知公告 -</p>-->
      <!--          </div>-->
      <!--          <div class="zixundongtai4">-->
      <!--            <el-button class="zixundongtai5" @click="gotoUrl('/tongzhigonggao?menuType=2')"-->
      <!--            >更多 ></el-button-->
      <!--            >-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="tongzhigonggao1">-->
      <!--          <div-->
      <!--              class="tongzhigonggao2" style="width: 1342px;"-->
      <!--              v-for="(item, index) in tzggList"-->
      <!--              :key="index + 400"-->
      <!--              @click="gotoZiXunDetialT(item)"-->
      <!--          >-->
      <!--            <div class="tongzhigonggao3">-->
      <!--              {{ item.title }}-->
      <!--            </div>-->
      <!--            <div class="tongzhigonggao4">-->
      <!--              <h class="tongzhigonggao4">{{ item.createTime }}</h>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="jcpx2 tabs-box">
        <el-tabs type="border-card" @tab-click="handleClick">
          <el-tab-pane label="推荐标准（基础项）">
            <el-table :data="list" height="280px">
              <el-table-column label="机构名称" header-align="center" prop="name" align="center"></el-table-column>
              <el-table-column prop="insType" label="机构类型" header-align="center" show-overflow-tooltip align="center">
                <template #default="{row}">
                  {{row.insType === '1' ? '乡镇卫生院' : row.insType === '2' ? '社区卫生服务中心' : row.insType === '3' ? '县人民医院' : row.insType === '4' ? '县中医院' : '县妇幼保健院'}}
                </template>
              </el-table-column>
              <el-table-column prop="areaName" label="所在地区" header-align="center" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="year" label="通过时间" header-align="center" show-overflow-tooltip align="center"></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="社区医院">
            <el-table :data="list" height="280px">
              <el-table-column label="机构名称" header-align="center" prop="name" align="center"></el-table-column>
              <el-table-column prop="insType" label="机构类型" header-align="center" show-overflow-tooltip align="center">
                <template #default="{row}">
                  {{row.insType === '1' ? '乡镇卫生院' : row.insType === '2' ? '社区卫生服务中心' : row.insType === '3' ? '县人民医院' : row.insType === '4' ? '县中医院' : '县妇幼保健院'}}
                </template>
              </el-table-column>
              <el-table-column prop="areaName" label="所在地区" header-align="center" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column prop="year" label="通过时间" header-align="center" show-overflow-tooltip align="center"></el-table-column>
            </el-table>
          </el-tab-pane>
          <div style="margin-top: 15px;display: flex; flex-direction: row; align-items: center;">
            <div style="flex: 1;"></div>
            <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalPage"
                background
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
          </div>

        </el-tabs>

      </div>

      <div class="jcpx2">
        <div class="jcpx2-1">
          <div class="zixundongtai2">
            <p class="zixundongtai3">通知公告 -</p>
          </div>
          <div class="zixundongtai4">
            <el-button @click="gotoUrl('/tongzhigonggao?menuType=2')" class="zixundongtai5"
            >更多 >
            </el-button
            >
          </div>
        </div>
        <div class="jcpx2-2">
          <div
              class="jcpx2-2-1"
              v-for="(item, index) in tzggList"
              :key="index + 15"
              @click="gotoZiXunDetialT(item)"
          >
            <div class="jcpx2-2-2">
              <div class="pp1">
                {{ item.title }}
              </div>
              <!--              <el-link :underline="false" class="pp1">{{item.title}}</el-link>-->
              <p class="pp2">{{ item.createTime.split(" ")[0] }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="jcpx2">
        <div class="jcpx2-1">
          <div class="zixundongtai2">
            <p class="zixundongtai3">资讯动态 -</p>
          </div>
          <div class="zixundongtai4">
            <el-button @click="gotoList()" class="zixundongtai5"
            >更多 >
            </el-button
            >
          </div>
        </div>
        <div class="jcpx2-2">
          <div
              class="jcpx2-2-1"
              v-for="(item, index) in cyxmList"
              :key="index + 15"
              @click="gotoDetial(item)"
          >
            <div class="jcpx2-2-2">
              <div class="pp1">
                {{ item.title }}
              </div>
              <!--              <el-link :underline="false" class="pp1">{{item.title}}</el-link>-->
              <p class="pp2">{{ item.createTime.split(" ")[0] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import gdMapData from "@/assets/guangdong.json";
import echarts from "echarts";
import http from "@/utils/http";
import chaozhou from "@/assets/chaozhou.json";
import dongguan from "@/assets/dongguan.json";
import foshan from "@/assets/foshan.json";
import guangzhou from "@/assets/guangzhou.json";
import heyuan from "@/assets/heyuan.json";
import huizhou from "@/assets/huizhou.json";
import jiangmen from "@/assets/jiangmen.json";
import maoming from "@/assets/maoming.json";
import meizhou from "@/assets/meizhou.json";
import qingyuan from "@/assets/qingyuan.json";
import shantou from "@/assets/shantou.json";
import shanwei from "@/assets/shanwei.json";
import shaoguan from "@/assets/shaoguan.json";
import shenzhen from "@/assets/shenzhen.json";
import xieyang from "@/assets/xieyang.json";
import yangjiang from "@/assets/yangjiang.json";
import yunfu from "@/assets/yunfu.json";
import zhanjiang from "@/assets/zhanjiang.json";
import zhaoqing from "@/assets/zhaoqing.json";
import zhongshan from "@/assets/zhongshan.json";
import zhuhai from "@/assets/zhuhai.json";

export default {
  name: "ChuangYouXiangMu",
  components: {Footer},
  data() {
    return {
      showBack: false,
      cyxmList: [],
      jsonMapList: {
        广州市: guangzhou,
        深圳市: shenzhen,
        佛山市: foshan,
        东莞市: dongguan,
        中山市: zhongshan,
        珠海市: zhuhai,
        江门市: jiangmen,
        肇庆市: zhaoqing,
        惠州市: huizhou,
        汕头市: shantou,
        潮州市: chaozhou,
        揭阳市: xieyang,
        汕尾市: shanwei,
        湛江市: zhanjiang,
        茂名市: maoming,
        阳江市: yangjiang,
        云浮市: yunfu,
        韶关市: shaoguan,
        清远市: qingyuan,
        梅州市: meizhou,
        河源市: heyuan,
      },
      jgData: [],
      tableData: [],
      tzggList: [],
      list: [],
      currentIndex: 1,
      inverterMonTimer: null,
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
    };
  },
  mounted() {
    this.inintMap();
    this.cyxmArr();
    this.tzggData()
    this.getDataList();
    //设置五分钟一次的定时刷新
    // this.$nextTick(() => {
    //   this.inverterMonTimer = setInterval(() => {
    //     console.log("-----------", "1")
    //     this.getTypeList();
    //   }, 30000);
    // });
  },
  beforeDestroy() {
    // if (this.inverterMonTimer) {
    //   clearInterval(this.inverterMonTimer);
    //   this.inverterMonTimer = null;
    // }
  },
  methods: {
    getDataList() {
      http.get('/api/declare/page', {params: Object.assign({
          current: this.pageIndex,
          size: this.pageSize
        }, {type: this.currentIndex})}).then((res) => {
        this.list = res.data.records
        this.totalPage = res.data.total
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    handleClick: function (tab) {
      console.log(tab.label)
      if(tab.label === '基本标准'){
        this.currentIndex = 0;
      } else if(tab.label === '推荐标准（基础项）') {
        this.currentIndex = 1;
      } else if(tab.label === '社区医院') {
        this.currentIndex = 2;
      }
      this.pageIndex = 1;
      this.getDataList();
    },
    getTypeList: function () {
      http.get("/api/declare/get/list/" + this.currentIndex).then((res) => {
        this.list = res.data;
      })
    },
    gotoList() {
      this.$router.push("/zixundongtai?menuType=2");
    },
    inintMap() {
      var mydata = [];
      this.showBack = false;
      http.get("/api/rate/get/map").then((res) => {
        if (res.code == 0) {
          mydata = res.data;
          this.jgData = res.data;
          // 初始化echarts示例mapChart
          var mapChart = echarts.init(document.getElementById("map-wrap"));
          echarts.registerMap("广东", gdMapData);
          // mapChart的配置
          var option = {
            title: {
              text: "区域分布 -",
              x: "left",
              left: "20",
              top: "20",
              textStyle: {
                color: "#024395",
              },
            },
            tooltip: {
              trigger: "item",
              backgroundColor: "rgba(255,255,255,1)", // 设置提示框的背景颜色
              textStyle: {
                color: "#000000", // 设置提示框文本的颜色
                textAlign: "left",
              },
              extraCssText: "box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);",
              formatter: function (params) {
                var name = `<span style="text-align: left;color: #024395; width: 100%;">${params.name}</span>`;
                return `<div style="display: flex; flex-direction: column; align-items: flex-start;">${name} <span>1.基本标准占比：<span style="text-align: left;color: #024395;">${params.data.basic}%</span></span> <span>2.推荐标准占比：<span style="text-align: left;color: #024395;">${params.value}%</span></span> </div>`; // 自定义tooltip的内容
              },
            },
            geo: {
              show: true,
              map: "广东",
              type: "map",
              itemStyle: {
                borderColor: "#FFFFFF", // 设置地图边界线的颜色为白色
                // 其他配置项...
              },
              label: {
                show: true,
                position: "inside",
                formatter: "{b}", // 显示地级市名称
                color: "#FFFFFF",
              },
              data: mydata,
            },
            series: [
              {
                name: "地级市", // series名称
                type: "map", // series图表类型
                map: "广东",
                coordinateSystem: "geo", // series坐标系类型
                itemStyle: {
                  borderColor: "#FFFFFF", // 设置地图边界线的颜色为白色
                  // 其他配置项...
                },
                label: {
                  show: true,
                  position: "inside",
                  formatter: "{b}", // 显示地级市名称
                  color: "#FFFFFF",
                },
                data: mydata, // series数据内容
                symbolSize: 10,
                hoverAnimation: true,
              },
              {
                name: "地级市", // series名称
                type: "scatter", // series图表类型
                map: "广东",
                coordinateSystem: "geo", // series坐标系类型
                symbol: "circle",
                symbolSize: 10,
                itemStyle: {
                  color: "transparent", // 设置内圆的颜色
                  borderColor: "#ffffff", // 设置外圆的颜色
                  borderWidth: 1, // 设置外圆的宽度
                },
                label: {
                  show: false,
                  position: "inside",
                  // formatter: "{b}", // 显示地级市名称
                  color: "#FFFFFF",
                },
                data: mydata, // series数据内容

                hoverAnimation: true,
              },
              {
                name: "地级市", // series名称
                type: "scatter", // series图表类型
                map: "广东",
                coordinateSystem: "geo", // series坐标系类型
                symbol: "emptyCircle",
                symbolSize: 6,
                itemStyle: {
                  color: "#ffffff", // 设置内圆的颜色
                  // borderColor: 'transparent'// 设置外圆的颜色
                },
                label: {
                  show: false,
                  position: "inside",
                  // formatter: "{b}", // 显示地级市名称
                  color: "#FFFFFF",
                },
                data: mydata, // series数据内容

                hoverAnimation: true,
              },
            ],
            visualMap: {
              min: 1,
              max: 100,
              left: "right",
              top: "bottom",
              text: ["推荐标准占比", "低"], // 文本，默认为数值文本
              realtime: false,
              calculable: true,
              inRange: {
                color: ["#f3f3f3", "#066AB0"],
              },
            },
          };
          mapChart.setOption(option);
          //取消监听，不然会重复触发点击事件
          mapChart.off("click");
          //监听点击事件
          mapChart.on("click", (param) => {

            // if (param.data.hasChildren == "1") {
            //   this.intChildMap(param);
            // }
          });
        }
      });
    },
    cyxmArr() {
      http
          .get("/api/public/article/page/", {
            params: {
              timestamp: new Date().getTime(),
              current: 1,
              size: 10,
              menuType: 2,
              type: 1,
            },
          })
          .then((response) => {
            // 处理响应数据
            this.cyxmList = response.data.records;
          })
          .catch((error) => {
            // 处理错误
            console.error(error);
          });
    },
    tzggData() {
      http
          .get("/api/public/article/list/2", {
            params: {
              timestamp: new Date().getTime(),
              menuType: '2',
            },
          })
          .then((response) => {
            // 处理响应数据
            this.tzggList = response.data;
            console.log("---------", this.tzggList);
          })
          .catch((error) => {
            // 处理错误
            console.error(error);
          });
    },
    gotoDetial(item) {
      this.$router.push("/zixundongtaixiangqing?id=" + item.id + "&type=1&type2=3");
    },
    gotoUrl(router) {
      this.$router.push(router);
    },
    handleLogin: function () {
      window.open("http://yzjcx.com", "_blank");
    },
    gotoZiXunDetialT(item) {
      this.$router.push(
          "/zixundongtaixiangqing?id=" + item.id + "&type=1&type2=4"
      );
    },
  },
};
</script>

<style scoped lang="css">
.tabs-box {
  padding: 16px;
  ::v-deep .el-tabs__header .el-tabs__item.is-active {
    color: #024395 !important;
  }
}
.ChuangYouXiangMu {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.ChuangYouXiangMu1 {
  margin: 0 auto;
  margin-top: -300px;
  width: 1440px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}

.map-child-back-back {
  width: 100px;
  height: 63px;
  position: absolute;
  left: 650px !important;
  top: 50px !important;
  z-index: 100;
}

.img-lunboback {
  width: 100%;
  height: 100%;
}

.jcpx1 {
  margin: 0px auto;
  opacity: 1;
  width: 1397px;
  height: 705px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
}

.jcpx1-1 {
  margin: 0px;
  width: 784px;
  height: 705px;
  border-radius: 20px;
  opacity: 1;
  display: flex;
}

.zixundongtai5 {
  margin: 0 auto;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  padding: 4px 16px;
  /* 文字/666 */
  color: #666666;
}

.jcpx1-2 {
  width: 597px;
  height: 705px;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.jcpx1-2-1 {
  margin: 0px;
  width: 191px;
  height: 51px;
  border-radius: 10px;
  opacity: 1;
  display: flex;

  align-items: center;
}

.yuanquanRed {
  width: 20px;
  height: 20px;
  color: #b72123;
  background: #ffd7d7;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  border-radius: 10px;
  text-align: center;
}

.yuanquanBlue {
  width: 20px;
  height: 20px;
  color: #024395;
  background: #d8d8d8;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  border-radius: 10px;
  text-align: center;
}

.p {
  height: 35px;
  opacity: 1;
  margin: 0 auto;
  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;

  z-index: 0;
}

.jcpx1-2-2 {
  width: 565px;
  height: 581px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
}

.jcpx1-2-2-2 {
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  letter-spacing: 0px;
  background: #f8f8f8;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #444444;
}

.jcpx2 {
  margin: 0px auto;
  opacity: 1;
  width: 1392px;
  display: flex;
  flex-direction: column;
}

.jcpx2-1 {
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 51px;
  background-color: white;
}

.jcpx2-2 {
  display: flex;
  width: 100%;
  border-radius: 20px;
  opacity: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-content: space-around;
  background: #fafafa;
  border-image: linear-gradient(270deg, #dcdcdc 0%, #f1f1f1 100%) 1;
  align-self: stretch;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.05);
}

.zixundongtai2 {
  display: flex;
  width: 127px;
  height: 51px;
  justify-content: center;
  align-items: center;
}

.zixundongtai3 {
  width: 121px;
  height: 35px;
  margin: 0 auto;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;
}

.zixundongtai4 {
  display: flex;
  width: 127px;
  height: 51px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jcpx2-2-1 {
  display: flex;
  width: 100%;
  opacity: 1;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #dcdcdc;
}

.jcpx2-2-1:first-child:hover {
  /* 在这里添加首个子元素的 hover 样式 */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.jcpx2-2-1:hover {
  background: #efefef;
}

.jcpx2-2-1:last-child {
  border-bottom: none;
}

.jcpx2-2-1:last-child:hover {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.jcpx2-2-2 {
  align-items: center;
  width: 1328px;
  height: 64px;
  opacity: 1;
  justify-content: space-between;
  /* 自动布局 */
  display: flex;
}

.pp1 {
  width: 900px;
  height: 18px;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  /* 文字/666 */
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.pp1:hover {
  cursor: pointer;
  color: #024395;
}

.pp2 {
  width: 180px;
  height: 18px;
  opacity: 1;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  flex-direction: row-reverse;
  /* 文字/888 */
  color: #888888;
}

.lunboback {
  margin: 0 auto;
  width: 1392px;
  height: 200px;
  display: flex;
}

.lunboback1 {
  width: 100%;
  height: 100%;
  /* background-color: #B72123; */
}

.defen {
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: 900;
  line-height: 18px;
  letter-spacing: 0px;
  background: linear-gradient(270deg, #9bc9ff 0%, #066ab0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  z-index: 3;
}

.diqu {
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;

  /* 文字/666 */
  color: #666666;

  z-index: 2;
}

.jgmc {
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  /* 文字/666 */
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 1;
}

.zixundongtai {
  flex-direction: column;
  display: flex;
  width: 1392px;
  height: 523px;
  margin: 0 auto;

  margin-top: 8px;
}

.zixundongtai1 {
  justify-content: space-between;
  display: flex;
  width: 1392px;
  height: 51px;
  background-color: white;
}

.zixundongtai2 {
  display: flex;
  width: 127px;
  height: 51px;
  justify-content: center;
  align-items: center;
}

.zixundongtai3 {
  width: 121px;
  height: 35px;
  margin: 0 auto;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;

  color: #024395;
}

.zixundongtai4 {
  display: flex;
  width: 127px;
  height: 51px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.zixundongtai5 {
  margin: 0 auto;
  display: flex;
  font-family: Source Han Sans CN;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0em;
  padding: 4px 16px;
  /* 文字/666 */
  color: #666666;
}

.tongzhigonggao {
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  margin-top: 8px;
  width: 1392px;
  min-height: 200px;
  max-height: 533px;
}

.tongzhigonggao1 {
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  margin-top: 8px;
  display: flex;
  max-height: 464px;
  border: 1px solid #d3e7ff !important; /* 设置边框为实线 */
  width: 1392px;
  padding: 16px 0px;
}

.tongzhigonggao2 {
  margin: 0 auto;
  display: flex;
  width: 1392px;
  height: 36px;
  align-self: stretch;
  padding: 0px 24px;
  align-items: center;
  justify-content: space-between;
}

.tongzhigonggao2:hover {
  background: #efefef;
}

.tongzhigonggao3 {
  width: 800px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 500;
  color: #666666;
}

.tongzhigonggao3:hover {
  cursor: pointer;
  color: #024395;
}

.tongzhigonggao4 {
  display: flex;
  width: 150px;
  height: 20px;
  ont-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  justify-content: right;

  /* 文字/888 */
  color: #888888;
}

@media screen and (max-width: 1440px) {
  .lunboback {
    width: 1152px;
  }

  .ChuangYouXiangMu1 {
    width: 1200px;
  }

  .jcpx1 {
    width: 1152px;
  }

  .jcpx1-2 {
    width: 357px;
  }

  .jcpx2 {
    width: 1152px;
  }

  .jcpx2-2-2 {
    width: 1088px;
  }
}
</style>
